<template lang="pug">
.partners.view
	section.invitation
		.section-content-wrapper
			h2 Партнерам
			h3 Приглашааем к сотрудничеству все компании, которые так или иначе коммуницируют со своими клиентами через SMS-сообщения.
			.grid
				.grid-column
					b Такими компаниями могут быть:
					ul
						li - операторы связи;
						li - рекламные агентства;
						li - банки, ретейл;
						li - порталы и сервисы;
						li - агрегаторы.
				.grid-column
					p Отдел продаж: sales@opt-in.ru
					p Служба технической поддержки: support@opt-in.ru
	section.feedback-form
		.section-content-wrapper
			h2 Форма заявки на оформление договора
			el-form.grid(ref="feedback_form" :model="feedback_form" @keydown.enter.native.prevent="sendFeedback" :rules="feedback_form_rules")
				.grid-column
					el-form-item(prop="message" label="Текст")
						el-input(v-model="feedback_form.message" type="textarea" :rows="7")

				.grid-column
					el-form-item(prop="company_name" label="Название компании")
						el-input(v-model="feedback_form.company_name")
					el-form-item(prop="name" label="Ваше имя")
						el-input(v-model="feedback_form.name")
					el-form-item(prop="contacts" label="Контактная информация")
						el-input(v-model="feedback_form.contacts")
					.captcha-row
						el-form-item
							img(:src="captcha.img")
						el-form-item(prop="captcha", label="")
							el-input.captcha-code(v-model="feedback_form.captcha" maxlength="4")
					el-button(@click="sendFeedback" type="primary") Отправить
</template>

<script>
import {mapActions} from "vuex";
import {
	GET_CAPTCHA,
	SEND_FEEDBACK_FORM,
} from "@/store/types"

const default_feedback_form = () => {
	return {
		company_name: '',
		name: '',
		contacts: '',
		message: '',
		captcha: '',
	}
}

export default {
	data() {
		return {
			feedback_form: default_feedback_form(),
			captcha: {
				img: null,
			},
			feedback_form_rules: {
				company_name: [
					{required: true, message: 'Укажите название компании', trigger: 'blur'},
				],
				name: [
					{required: true, message: 'Укажите ваше имя', trigger: 'blur'},
				],
				contacts: [
					{required: true, message: 'Укажите контактную информацию', trigger: 'blur'},
				],
				captcha: [
					{required: true, message: 'Символы', trigger: 'blur'},
					{min: 4, max: 4, message: '4 символа', trigger: 'blur'},
				],
			},
		}
	},
	created() {
		this.refreshCaptcha();
	},
	methods: {
		...mapActions([
			GET_CAPTCHA,
			SEND_FEEDBACK_FORM,
		]),
		refreshCaptcha() {
			return this.GET_CAPTCHA().then((captcha) => {
				this.captcha = captcha;
			}).catch((error) => {
				if (error.response.data.errors) {
					this.$root.pushAppMessages(error.response.data.errors.map((message) => {
						return this.$t(message);
					}))

					return Promise.reject(error);
				}
			});
		},
		sendFeedback() {
			this.$refs['feedback_form'].validate((valid) => {
						if (valid) {
							this.SEND_FEEDBACK_FORM({...this.feedback_form, ...{key: this.captcha.key}}).then(() => {
								this.$root.pushAppMessages('Сообщение отправлено', 'success');
							}).then(() => {
								this.refreshCaptcha();
								this.feedback_form = default_feedback_form();
							}).catch((error) => {
								this.refreshCaptcha();

								if (error.response.data.errors) {
									this.$root.pushAppMessages(error.response.data.errors.map((message) => {
										return this.$t(message);
									}))

									return Promise.reject(error);
								}
							});
						}
					}
			);
		},
	}
}
</script>

<style lang="scss" scoped>
.partners {
	background: $color--background;
	color: $color--black;

	//p {
	//	margin-bottom: 1.5em;
	//
	//	&:last-child {
	//		margin-bottom: 0;
	//	}
	//}

	.grid {
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr;
		grid-gap: 3rem;
	}

	ol {
		list-style-type: decimal;
		list-style-position: inside;
	}

	.intro, .invitation {
		background: $color--gray-light;
		color: #fff;
	}

	.captcha-row {
		display: flex;
	}

	.captcha-code {
		width: 5em;
	}

	@media (max-width: $max-width--tablets) {
		.grid {
			grid-template-columns: 1fr;
		}
	}
}
</style>
